<template>
    <div>
        <div class="paymentTicket">
            <h1>云助教系统</h1>
            <div class="paymentTicket-name">用户名 # 江雪松</div>
            <div class="van-hairline--bottom paymentTicket-order">订单号 # 123456789</div>
            <div class="paymentTicket-money">
                <div>测试俱乐部 - 测试支付</div>
                <div>¥ 0.01</div>
            </div>
            <div class="paymentTicket-all">
                <span>合计</span>
                <span style="margin-left:25px">¥ 0.01</span>
            </div>
            <div style="margin-top:40px">支付状态 SUCCESS</div>
            <div>联系电话 132221239493</div>
            <div>支付时间 2020-12-12 12:12:12</div>
            <div>付款号码 #494874646383922398874737473</div>
            <div class="paymentTicket-foot">©{{dayjs().format('YYYY')}}沪ICP备14040613号-4</div>
        </div>
        <div class="paymentTicket-email">如有疑问? 请联系 Email <a href="mailto://contact@wedots.cn">contact@wedots.cn</a ></div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    export default {
        name:"ticket",
        created () {
            
        },
        methods: {
            dayjs,
        },
    }
</script>

<style lang="less" scoped>
    .paymentTicket{
        background: #fff;
        font-size: 16px;
        width: 88%;
        margin: 0 auto;
        padding: 0 10px;
        margin-top: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        h1{
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            padding: 20px 0;
        }
        div{
            margin-bottom: 5px;
        }
        &-name{
            margin-top: 30px;
        }
        &-order{
            padding-bottom: 10px;
        }
        &-money{
            display: flex;
            justify-content: space-between;
            border-bottom: 3px solid #000;
            padding: 5px 0;
        }
        &-all{
            text-align: right;
            border-bottom: 3px solid #000;
            padding: 5px 0;
        }
        &-foot{
            padding-top: 50px;
            padding-bottom: 20px;
            text-align: center;
        }
        &-email{
            margin-top: 30px;
            text-align: center;
            color: #999;
        }
    }
</style>